import React from 'react';
import { Helmet } from 'react-helmet';
import Layout from 'components/Layout';
import Balancer from 'components/Balancer';

export default function BalancerPage({ pageContext: { useCase, useCasesPage, links, navigation, tag } }) {

  return (
    <>
    <Helmet>
      <meta property='og:image' content='/seo/preview.jpg' />
      <meta property='og:image:width' content={820} />
      <meta property='og:image:height' content={540} />
    </Helmet>
    <Layout
      isHeaderTransparent
      withGradient={true}
      page="balancer"
      links={links}
      navigation={navigation}
      tag={tag}
      metaTitle='Privacy Policy'
    >
      <Balancer
        useCase={useCase}
        back={useCasesPage.back_text}
      />
    </Layout>
    </>
  );
}
