import React from 'react';
import marked from 'marked';

import './Balancer.scss';

function Balancer({ useCase, back, caseStudy = '' }) {
  const renderList = (list) => list.map((item, index) => (
    <div className="balancer__content-section" key={index}>
      <p className="balancer__content-subtitle">
        {item.title}
      </p>
      {renderParagraphs(item.paragraphs)}
    </div>
  ));

  const renderParagraphs = (paragraphs) => paragraphs.map((paragraph, index) => (
    <p
      className="balancer__content-paragraph"
      key={index}
      dangerouslySetInnerHTML={{__html: marked.parseInline(paragraph, { breaks: true })}}
    />
  ));

  return (
    <section className="balancer">
      <div className="container container--narrow">
        <a
          href="javascript:history.back()"
          className="balancer__link"
          target="_self"
          rel="noopener noreferrer"
        >
          {back}
        </a>
        <p className="balancer__title section-title section-title--left">
          {useCase.title}
        </p>
        <p className="balancer__subtitle">
          {caseStudy}
        </p>
        <div className="balancer__content">
          {renderList(useCase.paragraphs)}
        </div>
      </div>
    </section>
  );
}

export default React.memo(Balancer);
